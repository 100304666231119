<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('partners.form.CreateAPartner')"
          :description="$t('partners.form.ThatIsWhereYouCanSetUpANewPartner')"
          v-if="$route.name == 'partnersCreate'"
        />
        <addHeader
          :title="$t('partners.form.PartnerModification')"
          :description="$t('partners.form.FromhereYouCanEditPartnerData')"
          v-if="$route.name == 'partnersEdit'"
        />
        <div class="mb-4 row">
          <dvider
            :title="$t('partners.form.Background')"
            :description="$t('partners.form.PartnerBackgroundInformation')"
            noLine="true"
          />

          <fileInput
            :title="$t('partners.form.PartnerPhoto')"
            v-model="item.image"
            :image="item.image"
            :path="'partners'"
            :hasErorr="errors.image"
            :error="$t('allerts.ChooseASuitableFile')"
            :col="'col-12'"
          />

          <formInput
            :title="$t('partners.form.PartnerIsName')"
            :checkError="'nameErorr'"
            v-model="item.name"
            maxlength="255"
            :hasErorr="errors.name"
            :error="$t('allerts.thisFieldIsRequired')"
          />
          <formInput
            :title="$t('partners.form.TaxIdentificationNumber')"
            v-model="item.tax_number"
            maxlength="255"
          />


          <searchGroupInput
           :type="'sectionsAccount'"
            v-model="item.sections_account_id"
            :values="$database.sectionsAccounts"
            :model="'sections_account'"
            :inputs="[{ 'show': 'name'}]"
          />

          <checkInput
            :title="$t('partners.form.PartnerStatus')"
            :value="item.stat"
            v-model="item.stat"
          />

          <dvider 
            :title="$t('customers.form.accountaSttings')" 
            :description="$t('customers.form.fromHereYouCanControlSettingsAccounts')"
          />
          
          <selectInput
            :title="$t('customers.form.balanceType')" 
            v-model="item.balance_type"
            :disabled="this.$route.name == 'partnersEdit'? true : false"
            :values="[
              {value: 0, name: 'تراكمي'},
              {value: 1, name: 'دائن ومدين'}
            ]"
          />

          <monyInput 
            :title="$t('customers.form.initialBalance')" 
            v-model="item.balance"
            :disabled="this.$route.name == 'partnersEdit' || item.balance_type == 1" 
            :checkError="'nameErorr'"
            :hasErorr="errors.balance"
            :error="$t('allerts.thisFieldIsRequired')"
          />

          <monyInput 
            v-if="item.balance_type == 1"
            :title="$t('customers.form.debit')" 
            v-model="item.debit" 
            :disabled="this.$route.name == 'partnersEdit'? true : false"
            :checkError="'nameErorr'"
            :hasErorr="errors.debit"
            :error="$t('allerts.thisFieldIsRequired')"
          />

          <monyInput 
            v-if="item.balance_type == 1"
            :title="$t('customers.form.creditor')" 
            v-model="item.creditor" 
            :disabled="this.$route.name == 'partnersEdit'? true : false" 
            :checkError="'nameErorr'"
            :hasErorr="errors.creditor"
            :error="$t('allerts.thisFieldIsRequired')"
          />


          <checkInput
            :title="$t('partners.form.KeyPartner')"
            :value="item.mainPartner"
            v-model="item.mainPartner"
          />

          <div 
            class="form-group row col-md-6"
            v-if="item.mainPartner == true">
            <label class="col-md-4"> نسبه الشراكه </label>
            <div class="add-input col-md-7">
              <input
                type="number"
                class="form-control"
                v-model="item.PartnershiRatio"
                :class="{ 'is-invalid': errors.rate }"
                @change="errors.rate = false"
                min="1"
                max="100"
              />
              <span><i class="far fa-percentage"></i></span>
            </div>
          </div>

          <subFileInput :title="$t('invoices.form.invoicesbalance')"
            v-model="item.file"
            :file="item.file"
            :path="'invoices'"
          />

          <dvider
            :title="$t('partners.form.BillingAddress')"
            :description="$t('partners.form.TheAddressForBillingIsRecordedInTheInvoice')"
          />

          <formInput
            :title="$t('partners.form.Address')"
            v-model="item.address1"
            maxlength="255"
          />
          <formInput
            :title="$t('partners.form.TheSequel')"
            v-model="item.address2"
            maxlength="255"
          />
          <formInput :title="$t('partners.form.TheSide')" v-model="item.entity" maxlength="255" />
          <formInput :title="$t('partners.form.City')" v-model="item.city" maxlength="255" />
          <formInput
            :title="$t('partners.form.ZipCode')"
            v-model="item.zip"
            maxlength="255"
          />
          <formInput :title="$t('partners.form.State')" v-model="item.country" maxlength="255" />

          <dvider
            :title="$t('partners.form.CommunicationInformation')"
            :description="$t('partners.form.PartnerCommunicationInformation')"
          />
          <iconInput
            :type="'text'"
            :title="$t('partners.form.MobileNumber')"
            :icon="'phone'"
            v-model="item.mobile"
          />
          <iconInput
            :type="'text'"
            :title="$t('partners.form.email')"
            :icon="'at'"
            v-model="item.email"
          />

          <!-- Divider -->
          <hr class="mt-5 mb-5 col-12" />
          <!-- Buttons -->
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'partnersCreate' && !$parent.stopEdit"
          >
            إنشاء شريك
          </a>
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'partnersEdit' && !$parent.stopEdit"
          >
            تعديل الشريك
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import formInput from "../elements/add/form/formInput.vue";
import fileInput from "../elements/add/form/fileInput.vue";
import monyInput from "../elements/add/form/monyInput.vue";
import selectInput from '../elements/add/form/selectInput.vue';
import checkInput from "../elements/add/form/checkInput.vue";
import iconInput from "../elements/add/form/iconInput.vue";
import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";
import loading from "../elements/add/loading.vue";
import connectionInput from '../elements/add/form/connectionInput.vue';
import searchGroupInput from "../elements/add/form/searchGroupInput.vue";
import subFileInput from "../elements/add/form/subFileInput.vue";

export default {
  data() {
    return {
      path: "/partners",
      item: {
        stat: 1,
        mainPartner: false,
        balance_type: 1,
        sections_account: {
        }
      },
      errors: {
        name: false,
        balanse: false,
      },
    };
  },
  methods: {
    saveItem() {
      var error = 0;
      if (typeof this.item.name === "undefined" || this.item.name == "") {
        error = 1;
        this.errors.name = true;
      }
      if (
        typeof this.item.balance !== "undefined" &&
        this.item.balance.length > 11
      ) {
        error = 1;
        this.errors.balance = true;
      }
      if (error == 0) {
        if (this.$route.name == "partnersCreate") {
          this.$parent.aletText =
            this.$t('allerts.partnerSuccessfullyAdde');
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));
          this.$router.go(-1);
        } else if (this.$route.name == "partnersEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
              if (response.data == "repeated") {
                this.$parent.aletText = "كود الشريك مكرر";
                this.$parent.alertType = "danger";
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                this.$parent.stopEdit = false;
                this.loading = false;
              } else {
                this.$parent.aletText =
                  this.$t('allerts.partnerHasBeenModifiedSuccessfully');
                this.$parent.alertType = "success";
                this.$router.go(-1);
                this.$parent.stopEdit = false;
                this.loading = false;
              }
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    getItem() {
      var item = this.$database.partners.find(
        (el) => el.id == this.$route.params.id
      );
      if (item) {
        this.item = item;
        var sectionsAccount = this.$database.sectionsAccounts.find(el => el.id == item.sections_account_id)
        if (sectionsAccount) {
          this.item.sections_account = sectionsAccount
        } else {
          this.item.sections_account = {}
        }
      }
      this.$parent.stopEdit = false;
    },
    claculateBalance () {
      this.item.balance = parseFloat(this.item.creditor && this.item.creditor != '' ? this.item.creditor : 0) - parseFloat(this.item.debit != '' && this.item.debit ? this.item.debit : 0) ;
      if (this.$option.balance_type == 1) {this.item.balance = this.item.balance * -1}
    }
  },
  watch: {
    "item.name": function (val) {
      var partner = this.$database.partners.find(
        (el) => el.name == val && el.id != this.item.id
      );
      if (typeof partner !== "undefined") {
        this.$parent.aletText = "اسم الشريك مكرر";
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    "item.tax_number": function (val) {
      var partner = this.$database.partners.find(
        (el) => el.tax_number == val && el.id != this.item.id
      );
      if (typeof partner !== "undefined") {
        this.$parent.aletText = "كود الشريك مكرر";
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    'item.debit': function () {
      this.claculateBalance()
    },
    'item.creditor': function () {
      this.claculateBalance()
    },
    'item.balance_type': function () {
      this.claculateBalance()
    }
  },
  mounted() {
    this.$updateDatabase(["partners"]);
    if (this.$route.name == "partnersEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    }

    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
    })
  },
  components: {
    formInput,
    fileInput,
    dvider,
    addHeader,
    monyInput,
    checkInput,
    iconInput,
    selectInput,
    loading,
    connectionInput,
    searchGroupInput,
    subFileInput,

  },
};
</script>
